.st-checkboxlabel{
    position: static;
    width: 120px;
    height: 24px;
    left: 32px;
    top: 0px;
    font-family: Gotham;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #003B71;
    flex: none;
    order: 1;
    flex-grow: 1;
    margin: 0px 8px;
    }

    .error {
        color:#db2269;
        font-size: 0.98em;
        display: relative;
      }

    .down-heading-style{
        font-style: italic;
    }
    
    .st-checkbox{
    left: 12.5%;
    right: 12.5%;
    top: 12.5%;
    bottom: 12.5%;
    background: #003B71;
    width: 16px !important;
    height: 16px !important;
    margin-right: 17px;
    // margin-top: 17px;
    }
    
    .st-checkbox-submit{
        display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 32px;
    position: static;
    width: 258px;
    height: 33.97px;
    left: calc(50% - 258px/2);
    top: 68.22%;
    bottom: 21.92%;
    background: #F0F7FA;
    border: 1px solid #0078B3;
    box-sizing: border-box;
    border-radius: 100px;
    flex: none;
    order: 5;
    align-self: stretch;
    flex-grow: 0;
    // margin: 8px 0px;
    }
    
    .st-checkbox-submit:hover{
        color: #fff;
        background-color: #003b71;
        border-color: #003b71;
        cursor: pointer !important;
    }
    
    .st-submit-div-checkbox{
        text-align: -webkit-center;
    }

    /* label {
        display: block;
        padding-left: 15px;
        text-indent: -15px;
      }
      input {
        width: 13px;
        height: 13px;
        padding: 0;
        margin:0;
        vertical-align: bottom;
        position: relative;
        top: -1px;
        *overflow: hidden;
      } */