@import './styles/mixins';
@import './styles/variables';

#chat-container,
#webchat__basic-transcript {
  height: 100vh;
}

.webchat__basic-transcript__activity {
  margin-top: 8px !important;
  padding-top: 0px !important;
}

.webchat__basic-transcript__activity-box {
  padding-bottom: 0px !important;
}

/*Not used Anywhere*/
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#loading > div > svg {
  margin: auto;
  display: block;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}
/**
   * Re-set default cursor for disabled elements.
   */
button[disabled],
html input[disabled] {
  cursor: default;
}

.webchat__stacked-layout__attachment {
  width: fit-content !important;
}

.webchat__stacked-layout__message{
  max-width: 100% !important;
}
.webchat__stacked-layout .webchat__stacked-layout__message{  
  margin-right: 96px;
  width: 100%;
}
.webchat__stacked-layout--from-user .webchat__stacked-layout__main{
  margin-left: 96px;
}
.webchat__stacked-layout--from-user .webchat__stacked-layout__message{
  margin-right: 0px !important;
  width: 100%;
}
.webchat__bubble:not(.webchat__bubble--from-user) .webchat__bubble__content{

  background:#ffffff !important;
  border-style:none !important;
  color:#003B71 !important;
}
.webchat__bubble--from-user .webchat__bubble__content{
  color:#ffffff !important;
}

.webchat__bubble--from-user .webchat__bubble__nub{
  width: 26px;
}

.webchat__bubble__content {
  /* default is 10px, adding 6px to match UX requirements */
  padding:16px; 
  // max-height: 600px;
  // overflow: auto !important;
}

.webchat__basic-transcript__activity-body{
  padding-bottom: 0;
}

// .webchat__bubble__content,
// button:default {
//   padding: 24px 15px;
//   @include boxShadow(0px 4px 20px 0px rgba(0, 0, 0, 0));
//   max-width: 530px;
// }

.webchat__bubble__content .markdown {
  padding: 0px;
  line-height: 24px;
}

.webchat__bubble__content .plain {
  padding: 0px;
}
.ac-actionSet {
  align-items: center !important;
  overflow-wrap: break-word;
}

.ac-pushButton {
  width: 258px;
  border-radius: 6px;
  padding: 16px !important;
  border: 1px solid $user-bubble-color !important;
  color: $user-bubble-color !important;
  font-family: $primary-font;
}

.ac-pushButton:hover,
.ac-pushButton:focus {
  color: $white-color !important;
  background-color: $user-bubble-color !important;
  cursor: pointer !important;
}

.ac-pushButton > .style-default > .ac-selectable > .card__action--performed{
  background-color: #003b70;
}
ac-pushButton[aria-pressed="true"]{
  background-color: #003b70;
}
.ac-pushButton.style-destructive{
  background-color: white !important;
}
.ac-pushButton.style-positive{
  background-color: #003b70;;
}
.ac-pushButton:hover div {
  word-wrap: break-word;
  font-weight: 600;
}

.ac-pushButton div {
  word-wrap: break-word;
  font-weight: 400;
  white-space: normal !important;
}

.ac-multichoiceInput {
  height: 52px;
  background: $white-color;
  border: 1px solid #8f949d;
}

.ac-choiceSetInput-multiSelect > div {
  margin-bottom: 24px;
}

.ac-choiceSetInput-multiSelect > div:last-child {
  margin-bottom: 0px;
}

.ac-container {
  padding: 0px !important;
}

.ac-horizontal-separator {
  height: 0px !important;
}

.ac-richTextBlock > span.ac-textRun {
 // background: rgba(243, 108, 13, 0.2) !important;
  color: $black-color !important;
 // padding: 4px 8px;
  font-size: 16px !important;
  border-radius: 2px;
}

.webchat__stacked-layout__message
  > div.webchat__bubble__content
  > div
  > p:nth-child(1)
  > strong {
  color: $user-bubble-color;
  font-weight: 800;
}

div.webchat__bubble__content div.ac-container.ac-selectable {
  background: $white-color !important;
}
.webchat__basic-transcript :focus {
  outline: none !important;
}

div.webchat__bubble__content
  > div
  > div
  > div
  > div
  > div.ac-container.ac-selectable
  > div
  > div:nth-child(5)
  > div
  > p,
div.webchat__bubble__content
  > div
  > div
  > div
  > div
  > div.ac-container.ac-selectable
  > div
  > div:nth-child(7)
  > div
  > p {
  color: $onfocus-color !important;
  font-weight: bold;
  text-decoration: underline;
}

.ac-columnSet > .ac-container > .ac-textBlock {
  color: $black-color !important;
}

div.webchat__bubble__content > div > div > div.ac-container.ac-selectable {
  background-color: transparent !important;
}

.ac-columnSet {
  cursor: pointer;
}

.webchat__bubble--from-user .webchat__bubble__content{
  color:#ffffff !important;
}


.ac-input-container {
  // margin: 8px 0;
  border-radius: 6px;
  // margin-bottom: 28px;
}

.ac-input-container select {
  padding: 16px;
  line-height: 18px;
  border: 1px solid #8f949d;
  box-sizing: border-box;
  border-radius: 4px;
  width: 258px !important;
}

.ac-input-container input {
  /* border-width: 0; */
  padding: 16px;
  outline: none;
  line-height: 18px;
  font-size: 16px;
  border-radius: 6px;
}

.ac-dateInput {
  background: $white-color;
}

#TimeChoice {
  border-bottom: 1px solid #b3b6c5;
  margin-bottom: 18px;
}

.ac-input-container input::placeholder {
  color: #58616b;
}

.ac-input .ac-multichoiceInput {
  height: 45px;
}

.locationCard > div:nth-child(3) > div > .ac-actionSet > button:first-child {
  pointer-events: none;
  opacity: 0.5;
}

.locationCard
  > .ac-columnSet
  > .ac-container
  > div:nth-child(5)
  > div
  > .ac-actionSet
  > button:first-child {
  pointer-events: none;
  opacity: 0.5;
}

.ac-input-container input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.facility-listings p > strong {
  color: $onfocus-color;
  text-decoration: underline;
}

.facility-details p > strong {
  color: #013b71;
}

.facility-details .ac-textBlock:last-of-type > p {
  color: #013b71;
  text-decoration: underline;
  padding-top: 16px;
}

.cb-error {
  background: #fddddd;
  color: #ac0000;
  position: fixed;
  padding: 8px;
  width: 100%;
  top: 0;
}

button[aria-label='Submit'] {
  border-radius: 100px !important;
  padding: 4px 16px !important;
  background: #f0f7fa !important;
  height: auto !important;
}

button[aria-label='Cancel'] {
  border-radius: 100px !important;
  margin-bottom: 24px;
  padding: 4px 16px !important;
  height: auto !important;
}

/** For the Select Doctor bubble background color*/
.ac-container .ac-adaptiveCard {
  background: $white-color !important ;
  border: 1px solid $white-color !important;
}

/*** custom button change  (bubble group component)***/
.ac-columnSet > .ac-container {
  flex: none !important;
}

/*Phone number border color*/
#phone1 .ac-input-container .ac-input {
  border: 1px solid;
}
#phone2 .ac-input-container .ac-input {
  border: 1px solid;
}
#phone3 .ac-input-container .ac-input {
  border: 1px solid;
}

.webchat__adaptive-card-renderer .ac-actionSet.ac-pushButton.style-positive{
  background-color: #f0f7fa;
}

.ac-pushbutton-background{
  background: #f0f7fa !important;;
}
// @media screen and (max-width: 741px) {
//   .webchat__bubble__content,
//   button:default {
//     max-width: 292px;
//   }
// }