@font-face {
  font-family: 'Gotham';
  src: local('Gotham'),
    url(../assets/fonts/Gotham/Gotham-Book.otf) format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Gotham';
  src: local('Gotham'),
    url(../assets/fonts/Gotham/Gotham-Medium.otf) format('opentype');
  font-weight: bold;
}
