@import '../../styles/mixins';
@import '../../styles/variables';

.st-btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

@media screen and (max-width: 741px) {
    .st-button-group {
      width: fit-content !important;
    }
  }

  // @media screen and (max-width: 741px) {
  //   .st-btn {
  //     display: block !important;
  //   }
  // }  

.st-btn-font {
  font-size: 16px;
  font-family: $primary-font;
  white-space: nowrap;
}

.st-btn-three-style{
  @include flex;
  @include flexDirection(column);
  // height: 65px;
  // width: 160px;
  white-space: break-spaces;
  @include displayInlineBlock;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 24px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  //   border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

 
}

.st-btn-primary {
  color: $user-bubble-color;
  border-color: $user-bubble-color;
  border-width: 1px;
  border-style: solid;
}

.st-btn-primary:hover {
  color: $white-color;
  background-color: $user-bubble-color;
  border-color: $user-bubble-color;
}

.st-btn-primary-checked {
  color: $white-color;
  background-color: $user-bubble-color;
  border-color: $user-bubble-color;
  border-width: 2px;
}

button,
input,
select {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.st-btn {
  @include flex;
  @include flexDirection(column);
  width: 118.5px;
  // height: 56px;
  @include displayInlineBlock;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 16px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.ant-tooltip-inner{
  background-color: #fff;
  color:black;
}

// .st-btn:hover{
//   overflow: visible;
//     white-space: normal;
//     width:auto;
// }

// .st-btn:after {
//   content:"";
//   //display: block;
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 40px;
//   height: 48px;
//  // z-index: 1;

//   pointer-events:initial;

// }

// .st-btn:hover:after{
//   cursor: pointer;
// }

// .tooltip {
//   //width: 60%;
//   visibility: hidden;
//   background-color: #fff;

//  // padding: 20px;
//   -webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.3);
//   opacity: 0;
//   transition: opacity 0.5s ease;
// }


// .st-btn:hover + .tooltip {
//   visibility: visible;
//   transition: opacity 0.5s ease;
//   opacity: 1;
// }


// .st-button-group {
//   width: 700px;
// }

/* .st-multiselectbtn{
display: flex;
flex-direction: column;
align-items: center;
padding: 16px;
position: static;
width: 118.5px;
left: calc(50% - 118.5px/2 - 189.75px);
top: 0%;
bottom: 0%;
background: #003B71;
border-radius: 6px;
flex: none;
order: 0;
flex-grow: 1;
margin: 0px 8px;
} */
.st-mr-5 {
  margin-right: 8px;
}
