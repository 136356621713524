@import '../../styles/variables';
@import '../../styles/mixins';

.custom-action-btn {
  width: 258px;
  background-color: $white-color;
  border: 1px solid $user-bubble-color;
  color: $user-bubble-color;
  padding: 16px;
  text-align: center;
  @include textDecoration(none);
  @include displayInlineBlock;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 6px;
  font-weight: 400;
  font-family: $primary-font;
}

.custom-action-btn:first-child {
  margin-top: 8px;
}

.custom-action-btn:hover {
  color: $white-color;
  background-color: $user-bubble-color;
}

.custom-action-container {
  @include flex;
  @include flexDirection(column);
}

.custom-action-container-action {
  margin-top: 24px;
}

.custom-action-container-action span:first-child {
  margin-right: 10px;
}
