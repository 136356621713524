@import '~antd/dist/antd.css';
@import './styles/global';

.App {
  text-align: left;
  font-size: 16px;
  height: 100%;
}

.cb-title {
  font-size: 30px;
  font-weight: bold;
}

.template-btn {
  color: $white-color;
  background-color: rgb(54, 54, 54);
}

.template-menu {
  float: right;
  margin-top: 16px;
}

.center {
  margin: auto;
}

.ant-card {
  filter: drop-shadow(2px 2px 3px rgb(212, 212, 212));
  margin-top: 15px;
}

.ant-modal {
  width: 80%;
}
// .ant-modal-content {
//   height: 85vh;
// }

.ant-row {
  padding-top: 20px;
}

.card-title {
  margin-bottom: 10px;
}

.ant-card-meta-title {
  color: #da4453 !important;
  font-weight: bold;
}

/*Not used Anywhere*/
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*Not used Anywhere*/
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*Not used Anywhere*/
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $white-color;
}

/*Not used Anywhere*/
.App-link {
  color: #61dafb;
}

/*Not used Anywhere*/
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 
.ant-layout-header {
  color: rgba(255,255,255,0.85);
  background: rgb(54,54,54);
} */

/*Not used Anywhere*/
.registration {
  margin-left: 75%;
  margin-top: 20px;
  background-color: #1890ff;
  color: $white-color;
}

/*Not used Anywhere*/
.list {
  margin: 50px;
}

.vertical-timeline--two-columns
  .vertical-timeline-element-content
  .vertical-timeline-element-date {
  color: blue !important;
}

.ant-btn-primary {
  background-color: $user-bubble-color;
  border-color: $user-bubble-color;
  height: 40px;
}

.valie-webchat {
  max-height: 600px;
}

.ant-layout-sider {
  color: $white-color;
  line-height: 120px;
  background: $white-color;
  margin-right: 200px;
  margin-top: 200px;
}

.required label {
  font-weight: bold;
}
.required label:after {
  color: #e32;
  content: ' *';
  display: inline;
}
.ant-select-selection {
  height: 50px;
  width: 400px;
}
input[type='text'] {
  font-size: 16px;
}

.ant-layout-content {
  @include flex;
}

// @media screen and (min-width: 742px) {
//   .App {
//     min-width: 742px;
//   }
// }
