$onfocus-color: #0078b3;
$default-color: #b3b6c5;
$dolphin-font-color: #677083;
$pebble-background-color: #f7f7f7;
$inky-chatboxinput-color: #0d1c3d;
$kp-logo-background-color: #f1f2f5;
$user-bubble-color: #003b71;
$white-color: #ffffff;
$black-color: #000000;

/** *************************************************
TYPOGRAPHY
******************************************************/

// $primary-body-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
//   'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//   sans-serif;

$primary-font: 'Gotham', sans-serif;
