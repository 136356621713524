@mixin flex {
  // write the css here
  display: -webkit-flex;
  display: flex;
}

@mixin flexShrink($value) {
  -webkit-flex-shrink: $value;
  flex-shrink: $value;
}

@mixin flexGrow($value) {
  -webkit-flex-grow: $value;
  flex-grow: $value;
}

@mixin alignSelf($value) {
  -webkit-align-self: $value;
  align-self: $value;
}

@mixin justifyContent($value) {
  -webkit-justify-content: $value;
  justify-content: $value;
}

@mixin alignItems($value) {
  -webkit-align-items: $value;
  align-items: $value;
}

@mixin flexShortHand($value) {
  -webkit-flex: $value;
  flex: $value;
}

@mixin flexDirection($value) {
  -webkit-flex-direction: $value;
  flex-direction: $value;
}

@mixin boxShadow($value) {
  -webkit-box-shadow: $value; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: $value; /* Firefox 3.5 - 3.6 */
  box-shadow: $value; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

@mixin textDecoration($value) {
  -moz-text-decoration: $value;
  -webkit-text-decoration: $value;
  text-decoration: $value;
}

@mixin displayInlineBlock {
  display: -moz-inline-block;
  display: inline-block;
}
