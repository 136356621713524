.font {
  font-size: 16px;
  font-family: Calibri, "Helvetica Neue", Arial, sans-serif;
}

.error {
  color:#db2269;
  font-size: 0.98em;
  display: relative;
}

// @media screen and (min-width: 992px) and (max-width:1300px){
//   .st-div-container {
//    width:510px;
//   }
// }

.st-btn-outline-primary {
  color: #0063B1;
  border-color: #0063B1;
  border-width: 2px;
}

.st-btn-font {
  font-size: 16px;
  white-space: nowrap;
  font-family: Gotham;
}

.st-submit-button:hover {
  color: #0063B1;
  background-color: #fff;
  border-color: #0063B1;
}

.st-submit-button {
  font-size: 16px;
  width: 110px;
  border-width: 2px !important;
  color: #fff;
  background-color: #0063B1;
  border-color: #0063B1;
}
.st-btn-main{
  display: inline-block;
  font-weight: 400;
  max-width:292px;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  border-radius:100px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}

.st-btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  border-radius: 6px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

.st-multiselectnotsure{
    color: #003B71;
    font-family: Gotham;
    font-size: 16px;
}

.st-div-container {
  padding: 1px;
  font-size: 16px;
}

.btn-font {
  font-size: 16px;
}

.st-p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.btn-group>.btn-group:not(:first-child), .btn-group>.btn:not(:first-child) {
  margin-left: 0px;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn:nth-child(n+3), .btn-group>:not(.btn-check)+.btn {
  border-radius: 4px;
}

.btn {
  width: 110px;
}

.caption {
  margin-bottom: 12px;
}

.input{
  width:96%;
  height:40px;
}

.mt-3{
  margin-top: 3px;
}

.st-multiselectlabel{
  position: static;
  left:0px;
  top: 40%;
  bottom: 0%;
  font-family: Gotham;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #003B71;
  flex: none;
  order: 0;
  flex-grow: 0;
  /* margin: 0px 10px; */
  padding-bottom: 8px;
  }
  
  .st-multiselectsubmit{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 32px;
  position: static;
  width: 258px;
  height: 33.97px;
  font-size: 16px !important;
  left: calc(50% - 110px/2);
  top: 91.39%;
  bottom: 3.44%;
  background: #F0F7FA;
  border: 1px solid #0078B3;
  box-sizing: border-box;
  border-radius: 100px;
  flex: none;
  order: 8;
  flex-grow: 0;
  margin: 8px 0px;
  font-size: 12px;
  font-family: 'Gotham';
  }
  
.st-div-multiselect-submit {
  text-align: -webkit-center;
}

.st-multiselectsubmit:hover,
.st-multiselectsubmit:focus {
  color: #fff;
  background-color: #003b71;
  border-color: #003b71;
  cursor: pointer !important;
}

// @media (max-width: 700px) {
//   .st-multiselectsubmit {
//     width: 100%;
//   }
// }
