.valri-form-container {
  //   padding-left: 20px;
  width: 100%;
  overflow: hidden;
}

.valri-form-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.valri-form-item {
  //   width: 450px;
  margin-right: 20px;
}

.valri-form-btn-container {
  //   width: 100px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.ant-layout {
  margin: 0px !important;
}

.valri-form-numeric-input {
  height: 56px;
}

.search-button {
  display: block;
  width: 165px;
  height: 56px;
  padding: 0px 10px;
  background-color: #0078b3;
  font-size: initial;
  border-radius: 0;
  position: relative;
  min-width: 0;
  font-family: gotham\5r;
  color: #fff;
  line-height: 24px;
  border: 1px solid #0078b3;
}

.search-button:disabled,
.search-button[disabled] {
  border: 1px solid rgb(149 191 212);
  background-color: rgb(149 191 212);
  color: #fff;
}

@media screen and (max-width: 741px) {
  .search-button {
    width: 85px;
  }
}

@media screen and (max-width: 575px) {
  .valri-form-btn-container {
    margin-top: 29px;
  }
}
