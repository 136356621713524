@import '../../styles/_mixins.scss';

.chat-container {
  @include flex;
  @include flexDirection(column);
  background-color: transparent;
}

.chat-wrapper {
  @include flex;
  height: 100%;
  order: 2;
}

.main-chat {
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  @include boxShadow(0px 4px 20px rgba(0, 0, 0, 0.04));
  overflow: hidden;
  @include flexShortHand(1);
  @include flex;
}

.container {
  background: #f7f7f7;
  @include flexShortHand(1);
}

@media screen and (min-width: 742px) {
  // .main-chat {
  //   min-width: 742px;
  // }

  .chat-container {
    height: calc(100% - 96px);
  }
  .st-chat-container {
    height: 100%;
  }
}

@media screen and (max-width: 741px) {
  .chat-container {
    height: calc(100% - 75px);
  }
}
