@import '../../styles/mixins';
@import '../../styles/variables';

.main-chat-height-container {
  @include flex;
  @include flexShortHand(1);
}
.main-chat-height {
  width: 100%;
  @include flexDirection(column);
  @include flex;
  @include flexShrink(1);
}

.main-chat-height-wrapper {
  overflow: hidden;
  @include flexShortHand(1);
  padding: 0px 0px 0px 0px;
}

.chat-inputbox-container-ptr-events
{
pointer-events: none;
opacity: 0.7;
}

.close-button{
  margin: 10px 0px 24px auto;
  width: 100px;
  height: 40px;
  // padding: 0px 30px;
  background-color: $onfocus-color;
  font-size: small;
  // border-radius: 0;
  // position: relative;
  // min-width: 0;
  font-family: $primary-font;
  color: $white-color;
  line-height: 24px;
  border: 1px solid $onfocus-color;
  float: right;
  border-radius: 2rem;
}

.endchat-button {
  margin: 10px 0px 24px auto;
  width: 165px;
  height: 55px;
  padding: 0px 30px;
  background-color: $onfocus-color;
  font-size: initial;
  border-radius: 0;
  position: relative;
  min-width: 0;
  font-family: $primary-font;
  color: $white-color;
  line-height: 24px;
  border: 1px solid $onfocus-color;
  float: right;
  border-radius: 2rem;
}

.input-box-disabled {
  pointer-events: none;
}
