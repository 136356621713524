@import '../../styles/variables';
@import '../../styles/mixins';

.webchat__icon-button {
  @include flex;
  @include flexShrink(0);
  @include alignSelf(stretch);
  @include justifyContent(center);
  @include alignItems(center);
  outline: 0;
  padding: 0;
  border: 0;
  width: 40px;
  background-color: Transparent;
}
.picker-container {
  position: fixed;
  width: 300px;
  top: 50px;
}
 
.webchat__send-box__main {
  @include flex;
  @include alignItems(stretch);
  background-color: White;
  min-height: 40px;
  margin: 16px 16px;
}

.webchat__fileContent{
  padding: 0px !important;
}

.webchat__fileContent__fileName {
  color: white !important;
  // text-decoration: underline;
  justify-content: center;
  display: flex;
}

// .webchat__fileContent__fileName::after {
//   content: ' - uploaded';
//   // text-decoration: none !important;
// }

.webchat__send-box-text-box {
  @include flexShortHand(1000);
  @include flex;
  @include alignItems(center);
  font-family: 'Calibri', 'Helvetica Neue', 'Arial', 'sans-serif';
  padding: 10px;
  position: relative;
  max-height: 140px;
  width: calc(100% - 40px);
}

.webchat__send-box-text-box__input {
  color: Black;
  @include flexShortHand(1);
  max-height: 200px;
  background-color: White;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  height: 100%;
  outline: 0;
  padding: 0;
  overflow: auto !important;
}

.webchat__send-box-text-box__control,
.webchat__send-box-text-box__highlighter {
  width: 100% !important;
}

.webchat__send-icon {
  fill: #ffffff !important;
}

.webchat__upload-button {
  @include flex;
  @include alignSelf(stretch);
  @include flexShrink(0);
  overflow: hidden;
  position: relative;
  width: 40px;
}

.webchat__icon-button {
  @include alignItems(center);
  @include flexShrink(0);
  @include alignSelf(stretch);
  @include flex;
  @include justifyContent(center);
  background-color: Transparent;
  border: 0;
  -webkit-box-pack: center;
  outline: 0;
  padding: 0;
  width: 40px;
}

.webchat__icon-button svg {
  fill: #767676;
}

.menu-autocomplete {
  position: absolute;
  top: auto;
  bottom: 100%;
  background-color: #e6e6e6;
}

.suggested-actions-container .react-film__filmstrip__list {
  float: right;
}

/******Input box container******/
.chat-inputbox-container {
  width: 100%;
  @include alignSelf(flex-end);
  @include flex;
  @include flexDirection(column);
  @include flexShrink(0);
  @include boxShadow(inset 0px 1px 0px rgba(0, 0, 0, 0.2));
}

.inputbox-container {
  //width: calc(100% - 72px);
  width:100%;
}

.chat-inputbox-wrapper {
  // width: 100%;
  @include flex;
  @include flexDirection(row);
  @include flexShortHand(1);
  // @include alignSelf(flex-start);
  background: $pebble-background-color;
}

// .end-chat{
//   width: 10%;
// }

.chat-inputbox-container-ptr-events
{
pointer-events: none;
opacity: 0.7;
}

.chat-microphone-container {
  width: 5%;
  @include flex;
  @include alignItems(center);
  @include justifyContent(center);
  @include alignSelf(flex-end);
  @include flexShrink(0);
  margin-right: 10px;
  overflow: hidden;
  margin-bottom: 30px;
}
.webchat__emojiicon {
  margin-left: 8px;
}

.chat-microphone {
  width: 32px;
  height: 32px;
}

.webchat__microphone-button__icon {
  fill: $onfocus-color !important;
}

.inputbox-wrapper {
  min-height: 52px;
  @include flex;
  @include alignSelf(flex-end);
  @include alignItems(center);
  @include justifyContent(space-between);
  margin-top: 8px;
  background: #ffffff;
  border: 1px solid $default-color;
  box-sizing: border-box;
  border-radius: 4px;
}

.inputbox-wrapper:focus-within {
  border: 1px solid $onfocus-color;
}

.chat-input-box {
  min-height: 20px;
  font-family: Gotham;
  font-size: 16px;
  line-height: 20px;
  border: hidden;
  background: $pebble-background-color;
  outline: none;
  color: $inky-chatboxinput-color;
  margin: 16px 10px;
}

.input-box-btn {
  width: 32px;
  height: 32px;
  @include flex;
  @include flexDirection(row);
  @include justifyContent(center);
  @include alignItems(center);
  @include alignSelf(flex-end);
  @include flexShortHand(none);
  @include flexGrow(0);
  background: $onfocus-color;
  border-radius: 4px;
  order: 0;
  padding: 0px;
  margin: 0px 10px 10px 0px;
}

.input-box-btn-disabled {
  pointer-events: none;
  background: $default-color;
}

.input-box-btn-enabled {
  background: $onfocus-color;
}

.input-character {
  font-family: Gotham;
  font-size: 12px;
  line-height: 16px;
  color: $dolphin-font-color;
}
/******End Input box container******/
