.st-dropdown-submit{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 2px 32px;
position: static;
width: 258px;
left: calc(50% - 258px/2);
padding-left:30px;
top: 68.22%;
bottom: 21.92%;
background: #F0F7FA;
border: 1px solid #0078B3;
box-sizing: border-box;
border-radius: 100px;
flex: none;
order: 5;
align-self: stretch;
flex-grow: 0;
margin: 8px 0px;
}

.st-dropdown-submit:hover{
    color: #fff;
    background-color: #003b71;
    border-color: #003b71;
    cursor: pointer !important;
}
