@import '../../styles/mixins';
@import '../../styles/variables';

.main-container {
  width: 100%;
  height: 100%;
  @include flex;
  @include flexDirection(column);
}

.logo-container {
  width: 100%;
  height: 96px;
  @include flex;
  @include flexDirection(column);
  border-bottom: 14px solid $inky-chatboxinput-color;
}

.logo-wrapper {
  @include flex;
}

.welcome-guest {
  margin-top: 12px;
  @include flex;

  @include alignItems(flex-start);
  padding: 32px 160px 0px;
  order: 1;
}

.welcome-guest-msg {
  color: $user-bubble-color;
  font-family: $primary-font;
  font-size: 48px;
  line-height: 52px;
  letter-spacing: -0.02em;
}

.kp-logo {
  background-color: $kp-logo-background-color;
  width: 288px;
  height: 82px;
  @include alignSelf(flex-start);
  margin-left: 38px;
}

@media screen and (max-width: 741px) {
  .logo-container {
    border: none;
    height: 75px;
  }
  .logo-wrapper {
    background: $inky-chatboxinput-color;
    width: 100%;
    height: 100%;
  }
  .kp-logo {
    background-color: $inky-chatboxinput-color;
    width: 44px;
    height: 42px;
    margin-top: 17px;
    margin-left: 16px;
  }
}
